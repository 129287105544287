import './App.css';

import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";

import ShabatImage from './images/shabat_bg.png'
import Sidebar from './components/Sidebar';
import ShabatPage from './pages/ShabatPage';
import About from './pages/About';
import UserSettings from './pages/UserSettings';
import { useEffect, useState } from 'react';
import ParashatHashavua from './pages/ParashatHashavua';

import { LoginProvider } from './components/LoginContext';
import { NotificationHandler } from './components/NotificationHandler';
import LoginPopup from './components/LoginPopup';
 
function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('load', handleLoad);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleLoad = () => {
  };

  const handlePopState = (event) => {
    window.history.back()
  }; 


  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);


  return (
    <LoginProvider>
      <NotificationHandler/>
      

      <div className="App" style={{ direction: "rtl", fontFamily: 'Rubik, sans-serif', fontWeight: "lighter" }}>
        
      <LoginPopup/>

        <div className='fixed mt-1.5 mr-4'>בס"ד</div>
        {!isOnline &&
          <div className='absolute bg-[#00000030] w-screen h-screen'>
            <div className='w-full bg-[#FFF4E5] py-4 shadow-lg rounded-md'>
              <p className='text-lg'> אין חיבור לאינטרנט 😢 </p>
              <p className='text-sm'> אנא בדוק את החיבור ונסה שוב </p>
            </div>
          </div>}

        <div style={{ direction: "rtl", background: `url(${ShabatImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} className="font-md h-screen w-full flex flex-col justify-center items-center rtl">
          <div className="overflow-auto font-md pb-8 h-screen w-full flex flex-col justify-center items-center rtl">
          <Router>
          
            <Sidebar />
            <Routes>
              <Route path="/" element={<ShabatPage />} />
              <Route path="about" element={<About />} />
              <Route path="settings" element={<UserSettings />} />
              <Route path="parasha" element={<ParashatHashavua />} />
              <Route path="*" element={<div></div>} />
            </Routes>
          </Router>

          </div>
        </div >
      </div >
    </LoginProvider>
  );
}

export default App;
