var isDebugMode = (process.env.REACT_APP_IS_PRODUCTION === 'false');
isDebugMode=true
const logger = {
  
  log: (...args) => {
    if (isDebugMode) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (isDebugMode) {
      console.error(...args);
    }
  },
  warn: (...args) => {
    if (isDebugMode) {
      console.warn(...args);
    }
  },
  info: (...args) => {
    if (isDebugMode) {
      console.info(...args);
    }
  },
};

export default logger;
