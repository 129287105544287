import React, { useState, useEffect } from 'react'
import RollingQuote from '../components/RollingQuote'
import { PiStarOfDavid } from 'react-icons/pi'
import { BsDot } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import call_backend_api from '../components/APIHandler'
import { CircularProgress } from '@mui/material'
import LoginPopup from '../components/LoginPopup'

const ShabatPage = () => {

    const [infoRecord, setInfoRecord] = useState(null)
    const textStyle = { "textShadow": "0 0 4px #ffffff, 0 0 8px #ffffff, 0 0 12px #ffffff, 0 0 16px #ffffff" }
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("----Requesting shabat record")
        call_backend_api("get_shabat_info_record", "GET", {}, setLoading)
    .then((backend_reponse) => {
    setInfoRecord(backend_reponse['shabat_record'])
        console.log("----changeing shabat infoRecord")
    })
    }, [])

    useEffect(() => {
        console.log("----infoRecordChanged")
    }, [infoRecord])


    function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero indexed
    const year = date.getFullYear();

    // Pad single digits with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}.${formattedMonth}.${year}`;
    }


    return (
        <div className='bg-[#ffffff80] shadow-lg w-[90%] h-[90%] flex flex-col items-center gap-2 text-xl overflow-y-auto' style={{ fontSize: '2.6vh' }}>

            {loading && (
                <div className='flex flex-col bg-opacity-0 absolute z-50 items-center justify-center w-full h-full -mt-48'>
                <CircularProgress style={{ color: '#2b8b9c' }}
                    size={60} 
                    thickness={5} />
                </div>
                ) 
            }
            <div className='mt-16'>
                <h1 style={textStyle} className='tracking-wide font-bold text-3xl pt-4'> שבת שלום </h1>
                <br></br>
                <p style={textStyle}> התאריך היום: {infoRecord ? formatDate(infoRecord["today_date"]) : ""} </p>
                <p style={textStyle}> השבת הקרובה : {infoRecord ? formatDate(infoRecord["shabat_date"]) : ""} </p>
                <p style={textStyle}> {infoRecord ? infoRecord["heb_date"] : ""} </p>
            </div>

            <PiStarOfDavid className='text-2xl text-[#2b8b9c]' />

            <table className="mt-2 w-10/12 [&_td]:w-1/3 rounded-xl bg-[#ffffff60] overflow-hidden">
                <tbody className='[&_th]:py-1 [&_tr:nth-child(odd)]:bg-[#ffffff40]'>
                    <tr className='[&_th]:font-normal'>
                        <th></th>
                        <th>כניסה</th>
                        <th>יציאה</th>
                    </tr>
                    <tr>
                        <td>ירושלים</td>
                        <td>{infoRecord ? infoRecord["Jerusalem_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["Jerusalem_out"].slice(0, -3) : ""}</td>
                    </tr>
                    <tr>
                        <td>תל אביב</td>
                        <td>{infoRecord ? infoRecord["TelAviv_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["TelAviv_out"].slice(0, -3) : ""}</td>
                    </tr>
                    <tr>
                        <td>חיפה</td>
                        <td>{infoRecord ? infoRecord["Hayfa_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["Hayfa_out"].slice(0, -3) : ""}</td>
                    </tr>
                    <tr>
                        <td>באר שבע</td>
                        <td>{infoRecord ? infoRecord["BeerSheva_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["BeerSheva_out"].slice(0, -3) : ""}</td>
                    </tr>
                </tbody>
            </table>

            <BsDot className='text-[#2b8b9c] text-small' />
            
            <Link to="/parasha" className='rounded-xl flex flex-col overflow-hidden items-center w-10/12'>
                <p className={"bg-[#ffffffa0] w-full py-0.2"}> פרשת השבוע: {infoRecord ? infoRecord["parasha"] : ""} </p>
                <RollingQuote shabat_info={infoRecord}/>
            </Link>
        </div>
    );
}

export default ShabatPage
